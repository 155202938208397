import { forwardRef, useState, useMemo } from 'react';

import { classNames } from '../../utils/classNames';
import { contactContent } from '../../constants/contact';
import { removeMarkdown } from '../../utils/removeMarkdown';
// import { createContactMarkup } from '../../utils/createContactMarkup';
import { getRandomInteger, truncateText } from '../../utils/truncateText';

import Markdown from './Markdown';
import CardPopup from './CardPopup';

import chevronIcon from '../../assets/templates-cards/chevron-icon.svg';
// import arrowIcon from '../../assets/templates-cards/diagonal-arrow.svg';

const TemplateCard = forwardRef(
	({ setContentActiveItems, image, header, text, maxWidth, isFirst, isLast, index, sectionName }, ref) => {
		const [isExpanded, setIsExpanded] = useState(false);
		const [isCardPopupOpen, setIsCardPopupOpen] = useState(false);

		const handleHighlighContentItem = (header) => {
			setContentActiveItems((prev) => {
				if (!prev.includes(header)) return [...prev, header];

				return prev;
			});
		};

		const toggleCard = (header) => {
			setIsExpanded((prev) => !prev);
			handleHighlighContentItem(header);
		};

		const isContactSection = sectionName === 'Contacts' || sectionName === 'Contact' || header.includes('Contact');
		const isLongText = text.length >= 1000;

		const truncatedText = useMemo(() => {
			const maxTextLength = isFirst ? 350 : getRandomInteger(200, 300);
			return isContactSection ? text : truncateText(removeMarkdown(text), maxTextLength);
		}, [text, isFirst, isContactSection]);

		const shouldShowImage = isExpanded || index % 10 !== 0;
		const shouldShowText = isExpanded || index % 10 !== 1;
		const displayedText = isExpanded ? text : truncatedText;

		return (
			<div
				ref={ref}
				key={isExpanded ? 'expanded' : 'collapsed'}
				className={classNames(
					'w-full h-fit flex flex-col gap-[22px]',
					'p-[18px] rounded-[26px] shadow-cardShadow bg-backgroundColor-secondary',
					maxWidth ? maxWidth : 'w-full md:max-w-[395px]',
					isLast ? 'mb-0' : 'mb-[22px] md:mb-[25px]',
					'transition-height duration-300 ease-in-out z-[80]'
				)}
			>
				{isContactSection ? (
					<img src={contactContent.img} alt='' className='rounded-2xl' />
				) : (
					image && shouldShowImage && <img src={image} alt='' className='rounded-2xl' />
				)}

				<h3 className='font-bold text-[26px] leading-[36px]'>
					{isContactSection ? removeMarkdown(contactContent.header) : removeMarkdown(header)}
				</h3>

				{text && shouldShowText && (
					<>
						{isContactSection ? (
							<>
								<p className='font-medium text-sm leading-[25px] whitespace-normal'>{contactContent.beforeText}</p>

								<ul className='flex flex-col gap-2 my-3'>
									{contactContent.contacts.map(({ icon, link, name, text }, index) => (
										<li key={index}>
											<a
												href={link}
												target={name !== 'Phone' ? '_blank' : '_self'}
												rel='noopener noreferrer'
												className='text-accent flex items-center gap-2'
											>
												<img src={icon} alt={`${name} Icon`} className='w-[14px] h-[14px]' />

												<div className='flex items-center gap-1'>
													<span className='font-semibold'>{name}:</span>
													<span>{text}</span>
												</div>
											</a>
										</li>
									))}
								</ul>

								<p className='font-medium text-sm'>{contactContent.afterText}</p>
							</>
						) : (
							<span className='font-medium text-sm leading-[25px] whitespace-normal'>
								<Markdown displayedText={displayedText} />
							</span>
						)}
					</>
				)}

				<div className='flex items-center justify-between'>
					{sectionName && (
						<div className='flex items-center gap-[6px]'>
							<div className='w-[17px] h-[17px] rounded-full bg-accentGradient' />
							<span className='text-[12px] leading-[16px]'>{sectionName}</span>

							{/* <div>
							<img src={arrowIcon} alt='arrow' />
						</div> */}
						</div>
					)}

					{isLongText ? (
						<button
							className={classNames(
								'w-[107px] h-[30px]',
								'text-xs text-input-placeholder font-medium',
								'ml-auto rounded-[30px] bg-[#E9F0FF]'
							)}
							onClick={() => {
								setIsCardPopupOpen(true);
								handleHighlighContentItem(header);
							}}
						>
							See More
						</button>
					) : (
						<button onClick={() => toggleCard(header)} className={classNames(!sectionName ? 'ml-auto' : '')}>
							<img src={chevronIcon} alt='collapse' className={classNames(isExpanded ? 'rotate-180' : '')} />
						</button>
					)}
				</div>

				<CardPopup
					isOpen={isCardPopupOpen}
					handleClose={() => setIsCardPopupOpen(false)}
					cardContent={{ image, header, text }}
					className={[
						'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2',
						'max-w-[849px] w-full px-2 md:px-3 lg:px-4 z-[81]',
					]}
					zIndex='z-[80]'
				/>
			</div>
		);
	}
);

export default TemplateCard;
