export const getRandomInteger = (min, max) => {
	const range = max - min;
	const randomValue = Math.floor(Math.random() * (range * 3 + 1)) - range;

	return Math.max(min, Math.min(max, randomValue + min));
};

export const truncateText = (text, maxLength) => {
	if (!text) return '';

	if (text.length <= maxLength) return text;
	return text.slice(0, maxLength) + '...';
};
