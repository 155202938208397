import { classNames } from '../../utils/classNames';

import CircleButton from '../buttons/CircleButton';
import arrowIcon from '../../assets/input/black-arrow-icon.svg';
import VoiceRequest from './VoiceRequest';

const InputField = ({ value, onChange, onSubmit, formLoading, recordingLoading, setRecordingLoading, className }) => {
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			onSubmit(event, value);
		}
	};

	return (
		<div
			className={classNames(
				'relative max-w-[697px] md:max-w-[709px] w-full h-[58px] md:h-[78px] flex items-center gap-2 z-20',
				className
			)}
		>
			{/* gradient base */}
			<div className='absolute inset-0 max-w-[709px] flex items-center gap-2'>
				<div className='max-w-[697px] md:max-w-[657px] w-full h-full rounded-[123px] bg-backgroundColor-secondary' />
				<div className='h-8 md:h-11 w-8 md:w-11 aspect-square' />
			</div>

			<div
				className={classNames(
					'max-w-[657px] w-full h-full',
					'flex items-center justify-center',
					'p-2 rounded-[123px] bg-accentGradientLight z-20'
				)}
			>
				<div
					className={classNames(
						'w-full h-11 md:h-[62px] flex items-center justify-between gap-[22px]',
						'p-3 pl-[22px] border border-input-border rounded-[123px] bg-backgroundColor-secondary'
					)}
				>
					<input
						value={value}
						onChange={(event) => onChange(event.target.value)}
						onKeyDown={handleKeyDown}
						placeholder='Ask a question'
						className={classNames(
							'w-full z-20',
							'bg-transparent focus:outline-none',
							'placeholder:text-input-placeholder',
							'placeholder:text-sm md:placeholder:text-[16px]',
							'placeholder:leading-[22px]'
						)}
					/>

					<CircleButton iconSRC={arrowIcon} iconALT='arrow' className='bg-button-light' type='submit' />
				</div>
			</div>

			<VoiceRequest
				handleSubmit={onSubmit}
				formLoading={formLoading}
				recordingLoading={recordingLoading}
				setRecordingLoading={setRecordingLoading}
			/>
		</div>
	);
};

export default InputField;
