import { classNames } from '../utils/classNames';

import MobileLogo from '../components/MobileLogo';
import sloganIcon from '../assets/hero/slogan-icon.svg';

const HeroSection = () => {
	return (
		<section className='max-w-[876px] w-full mb-12 flex flex-col items-center z-20'>
			<div className='flex flex-col items-center'>
				<div className='flex items-center gap-1 text-center'>
					<p
						className={classNames(
							'text-[10px] md:text-xs custom:text-xs',
							'leading-[14px] md:leading-4 custom:leading-4',
							'font-medium bg-accentGradient text-gradient py-5'
						)}
					>
						Welcome to <span className='font-extrabold text-[#1A6EEC]'>Genum</span>: <br className='sm:hidden' /> the
						first site for personalized answers powered by AI
					</p>

					<img src={sloganIcon} alt='slogan icon' className='mt-3 sm:mt-0' />
				</div>

				<MobileLogo className='lg:hidden' />
			</div>

			<div
				className={classNames(
					'flex flex-col items-center w-full',

					'mt-16 md:mt-20 lg:mt-44 custom:mt-28 custom:mb-8 xxl:mb-0 xxl:mt-44',
					'text-[57px] leading-[149px] font-extrabold'
				)}
			>
				<h1
					className={classNames(
						'text-[57px] xs:text-[65px] sm:text-[75px] md:text-[90px] lg:text-[105px] xl:text-[115px] xxl:text-[117px]',
						'mr-[130px] sm:mr-[180px] md:mr-[280px] xl:ml-16 xxl:ml-6',
						'font-extrabold xl:self-start'
					)}
				>
					AI-First
				</h1>

				<span
					className={classNames(
						'text-[57px] xs:text-[65px] sm:text-[75px] md:text-[90px] lg:text-[100px] xl:text-[110px] xxl:text-[122px]',
						'font-extralight xl:self-end',
						'ml-[50px] sm:ml-[100px] md:ml-[200px] xl:mr-16 xxl:mr-0',
						'-mt-24 xs:-mt-20 sm:-mt-18 md:-mt-14 lg:-mt-12'
					)}
				>
					Automation
				</span>
			</div>

			<p
				className={classNames(
					'text-[12px] md:text-[14px] xxl:text-[16px]',
					'leading-[20px] xl:leading-[22px] custom:leading-[23px] xxl:leading-[30px]',
					'font-medium max-w-[230px] md:max-w-[405px] w-full',
					'-mt-4 sm:mt-2 md:mt-10 custom:mt-9 xxl:mt-8 mr-4 md:mr-4'
				)}
			>
				Empowering Businesses with <span className='font-bold'>AI-driven Automation and AI Employees</span> for the
				Exponential Growth
			</p>
		</section>
	);
};

export default HeroSection;
