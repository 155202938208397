import { useState, useRef, useEffect } from 'react';

import { getArticles } from '../../axios/API';
import { classNames } from '../../utils/classNames';
import { useAppContext } from '../../context/AppContextProvider';

import DesktopLogo from '../DesktopLogo';
import Navigation from './Navigation';
import RequestHeading from '../request-record/RequestHeading';
import RequestRecordPopup from '../request-record/RequestRecordPopup';

const FloatingSidebar = () => {
	const {
		sectionRefs,
		handleChangeSectionRefs,
		navigationPath,
		setNavigationPath,
		setContentList,
		availableSectionsList,
		setAvailableSectionsList,
		addUserQueryMessage,
		setFormLoading,
		sectionLoading,
		setSectionLoading,
		requestRecord,
		setIsRequestPopupOpen,
		setHomeSectionVisible,
	} = useAppContext();

	const [navigationFixed, setNavigationFixed] = useState(false);
	const [scrollPosition, setScrollPosition] = useState(0);

	const navRef = useRef(null);
	const logoRef = useRef(null);

	useEffect(() => {
		if (window.history.scrollRestoration) window.history.scrollRestoration = 'manual';
	}, []);

	useEffect(() => {
		const handleScroll = () => {
			if (window.innerWidth < 1024) return;

			if (navRef.current && logoRef.current) {
				const navRect = navRef.current.getBoundingClientRect();
				const logoRect = logoRef.current.getBoundingClientRect();
				const currentScrollPosition = window.scrollY;

				if (navRect.top <= logoRect.bottom && !navigationFixed) {
					setNavigationFixed(true);
					setScrollPosition(currentScrollPosition);
				} else if (navigationFixed && currentScrollPosition < scrollPosition) {
					setNavigationFixed(false);
				}
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => window.removeEventListener('scroll', handleScroll);
	}, [navigationFixed, scrollPosition, setHomeSectionVisible]);

	const handleScrollToRequest = async (request) => {
		const currentRequestSection = sectionRefs.find(({ section }) => section === request)?.ref;

		if (currentRequestSection && currentRequestSection.current) {
			setIsRequestPopupOpen(false);

			currentRequestSection.current.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
			});
		} else {
			setFormLoading(true);
			addUserQueryMessage(request);
			setIsRequestPopupOpen(false);
			const sectionRef = handleChangeSectionRefs(request);

			try {
				const { data } = await getArticles(request);

				setContentList((prev) => [
					...prev,
					{
						type: 'form-response',
						sectionRef: sectionRef,

						// sectionName: NAVIGATION.find((element) => element.value === value).label,
						// sectionId: NAVIGATION.find((element) => element.value === value).value,

						items: data.block.items,
						questions: data.questions,

						// nextPage: pageParam + 1,
						// hasMore: availableSectionsList.length > 1,
					},
				]);
			} catch (error) {
				console.error(error);
			} finally {
				setFormLoading(false);
				setTimeout(() => {
					if (sectionRef.current) {
						sectionRef.current.scrollIntoView({
							behavior: 'smooth',
							block: 'start',
						});
					}
				}, 100);
			}
		}
	};

	return (
		<aside
			className={classNames(
				'absolute top-0 left-0 min-h-[100dvh] h-full',
				'hidden lg:flex flex-col items-center',
				'transition-all duration-300 z-[70]'
			)}
		>
			<div className='relative w-full h-full'>
				<DesktopLogo logoRef={logoRef} />

				<nav
					ref={navRef}
					className={classNames(
						'pl-8 xl:pl-12 xxl:pl-[60px] pt-7 z-20',
						'transform translate-y-0 transition-transform duration-300',

						navigationFixed
							? 'fixed top-[51px]'
							: 'absolute lg:mt-[870px] xl:mt-[880px] custom:mt-[820px] xxl:mt-[880px]'
					)}
				>
					<Navigation
						className='w-[144px] flex flex-wrap gap-y-[16px]'
						sectionRefs={sectionRefs}
						handleChangeSectionRefs={handleChangeSectionRefs}
						navigationPath={navigationPath}
						setNavigationPath={setNavigationPath}
						setContentList={setContentList}
						availableSectionsList={availableSectionsList}
						setAvailableSectionsList={setAvailableSectionsList}
						sectionLoading={sectionLoading}
						setSectionLoading={setSectionLoading}
					/>

					<div>
						{requestRecord.length > 0 && (
							<div className='max-w-[160px] xxl:max-w-[198px] w-full flex flex-col gap-3 mt-10 xxl:mt-[70px] text-black/60'>
								<RequestHeading mode='sidebar' handleClick={() => setIsRequestPopupOpen(true)} />

								<ul className='flex flex-col gap-2 pl-2 border-l border-[#000000]/20'>
									{requestRecord.slice(-3).map((request) => (
										<li
											key={request}
											className={classNames(
												'w-full cursor-pointer break-words truncate',
												'transition-all duration-300 ease-in-out',
												'font-medium text-xs hover:text-[#57aeff]'
											)}
											onClick={() => handleScrollToRequest(request)}
										>
											{request}
										</li>
									))}
								</ul>
							</div>
						)}
					</div>
				</nav>

				<RequestRecordPopup
					className={[
						'fixed top-[270px] custom:top-[200px] xxl:top-[300px]',
						'max-w-[387px] max-h-[354px] w-full h-full',
						'ml-[190px] xxl:ml-[200px]',
					]}
				/>
			</div>
		</aside>
	);
};

export default FloatingSidebar;
