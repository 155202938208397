import { classNames } from '../../utils/classNames';

const CircleButton = ({ type = 'button', iconSRC, iconALT, className, handleClick, disabled }) => {
	return (
		<button
			type={type}
			className={classNames(
				'h-8 md:h-11 w-8 md:w-11 aspect-square',
				'flex items-center justify-center rounded-full',
				className
			)}
			onClick={handleClick}
			disabled={disabled}
		>
			<img src={iconSRC} alt={iconALT} />
		</button>
	);
};

export default CircleButton;
