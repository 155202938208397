import { getArticles } from '../../axios/API';
import { classNames } from '../../utils/classNames';
import { NAVIGATION } from '../../constants/navigation';

const Navigation = ({
	mode,
	setMobileNavigationOpen,
	className,
	sectionRefs,
	handleChangeSectionRefs,
	navigationPath,
	setNavigationPath,
	setContentList,
	availableSectionsList,
	setAvailableSectionsList,
	sectionLoading,
	setSectionLoading,
}) => {
	const fetchSectionData = async (value, sectionRef) => {
		setSectionLoading(true);

		try {
			const { data } = await getArticles(value);

			setAvailableSectionsList((prev) => prev.filter((element) => element !== value));
			setContentList((prev) => [
				...prev,
				{
					type: 'section',
					sectionRef: sectionRef,
					sectionName: NAVIGATION.find((element) => element.value === value).label,
					sectionId: NAVIGATION.find((element) => element.value === value).value,

					items: data.block.items,
					questions: data.questions,
				},
			]);
		} catch (error) {
			console.error(error);
		} finally {
			setSectionLoading(false);
		}
	};

	const handleNavigationClick = async (value) => {
		if (sectionLoading) return;

		let sectionRef = sectionRefs.find(({ section }) => section === value).ref;

		if (!sectionRef.current) {
			sectionRef = handleChangeSectionRefs(value);
		}

		await fetchSectionData(value, sectionRef);

		setTimeout(() => {
			setNavigationPath(value);

			if (sectionRef.current) {
				sectionRef.current.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
		}, 200);
	};

	return (
		<ul className={classNames(className)}>
			{NAVIGATION.map(({ label, value, IconComponent }, index) => {
				const isActive = navigationPath === value;
				const isAvailable = availableSectionsList.includes(value);

				return (
					<li
						key={label}
						onClick={() => {
							handleNavigationClick(value, index);
							mode === 'mobile' && setMobileNavigationOpen(false);
						}}
						className={classNames(
							'w-[71px] flex flex-col gap-[10px] items-center justify-between',
							'group transition-all duration-300 ease-in-out cursor-pointer'
						)}
					>
						<IconComponent
							className={classNames(
								'transition-all duration-300 ease-in-out',
								isActive ? 'fill-[#B7D0FF]' : 'fill-white',
								'group-hover:fill-[#B7D0FF]',
								!isAvailable ? 'fill-[#B7D0FF]' : ''
							)}
						/>

						<span className='font-semibold text-[11px] leading-[15px]'>{label}</span>
					</li>
				);
			})}
		</ul>
	);
};

export default Navigation;
