import bigBlueEclipse from '../assets/big-blue-eclipse.svg';
import CustomizeExperienceCard from '../components/template-cards/CustomizeExperienceCard';

const HomeSection = () => {
	return (
		<section className='relative w-full my-12 mb-8 md:mb-[73px] flex flex-col items-center'>
			<img
				loading='lazy'
				src={bigBlueEclipse}
				alt='decoration'
				className='absolute -top-[250px] sm:-top-[300px] lg:-top-[450px] xl:-top-[570px] blur-[50px] md:blur-[30px]'
			/>

			<CustomizeExperienceCard />
		</section>
	);
};

export default HomeSection;
