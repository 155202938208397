import ReactMarkdown from 'react-markdown';

const Markdown = ({ displayedText }) => {
	return (
		<ReactMarkdown
			components={{
				ol: ({ children }) => {
					return <ul className='flex flex-col gap-2 my-3 list-inside list-decimal'>{children}</ul>;
				},

				ul: ({ children }) => {
					return <ul className='flex flex-col gap-2 my-3 list-inside list-disc'>{children}</ul>;
				},

				li: ({ children }) => {
					// const hasNestedList = Children.toArray(children).some((child) => {
					// 	return child?.props?.node?.tagName === 'ul' || child?.props?.node?.tagName === 'ol';
					// });

					return <li>{children}</li>;
				},

				p: ({ children }) => <p className='mb-4'>{children}</p>,
			}}
		>
			{displayedText}
		</ReactMarkdown>
	);
};

export default Markdown;
