import { classNames } from '../utils/classNames';
import ResponseWrapper from './template-cards/ResponseWrapper';

const SectionContainer = ({
	page,
	pageIndex,
	formLoading,
	questionLoading,
	isFetchingNextPage,
	isLastPage,
	isFirstPage,
}) => {
	return (
		<section key={pageIndex} id={page.sectionId} ref={page.sectionRef} className='relative'>
			{!isFirstPage && <div className='h-20 lg:h-4 w-full' />}

			<div
				className={classNames(
					'relative flex justify-center w-full',
					isLastPage && (formLoading || isFetchingNextPage || questionLoading)
						? 'pb-0'
						: isLastPage
						? 'pb-36'
						: 'lg:pb-12'
				)}
			>
				{page.type === 'section' && <ResponseWrapper page={page} sectionLoading={isFetchingNextPage} />}

				{page.type === 'user-message' && (
					<div className='max-w-[600px] w-fit ml-auto text-right py-[10px] px-8 rounded-[88px] bg-accentGradientLight'>
						<span className='font-semibold text-lg leading-[25px] break-words'>{page.items[0].text}</span>
					</div>
				)}

				{page.type === 'form-response' && <ResponseWrapper page={page} />}

				{page.type === 'questions' && <ResponseWrapper page={page} />}
			</div>
		</section>
	);
};

export default SectionContainer;
