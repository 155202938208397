import { useRef, useEffect } from 'react';
import { classNames } from '../../utils/classNames';

const Popup = ({ isOpen, handleClose, children, className, zIndex }) => {
	const popupContentRef = useRef();

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'auto';
		}

		return () => (document.body.style.overflow = 'auto');
	}, [isOpen]);

	useEffect(() => {
		if (!isOpen) return;

		const handleClickOutside = (event) => {
			if (popupContentRef.current && !popupContentRef.current.contains(event.target)) {
				handleClose();
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => document.removeEventListener('mousedown', handleClickOutside);
	}, [isOpen, handleClose, popupContentRef]);

	return (
		isOpen && (
			<>
				<div className={classNames('px-4 z-[61]', className)}>
					<div ref={popupContentRef} onMouseDown={(event) => event.stopPropagation()}>
						{children}
					</div>
				</div>

				<div
					className={classNames(
						'fixed inset-0 bg-black transition-all duration-500 ease-in-out z-[60]',
						isOpen ? 'opacity-[56%] visible' : 'opacity-0 invisible',
						zIndex
					)}
				/>
			</>
		)
	);
};

export default Popup;
