import { useAppContext } from '../../context/AppContextProvider';

import RequestRecord from './RequestRecord';
import Popup from '../popup/Popup';

const RequestRecordPopup = ({ className }) => {
	const {
		sectionRefs,
		requestRecord,
		setContentList,
		addUserQueryMessage,
		handleChangeSectionRefs,
		setFormLoading,
		isRequestPopupOpen,
		setIsRequestPopupOpen,
	} = useAppContext();

	return (
		<Popup isOpen={isRequestPopupOpen} handleClose={() => setIsRequestPopupOpen(false)} className={className}>
			<RequestRecord
				sectionRefs={sectionRefs}
				requestRecord={requestRecord}
				setContentList={setContentList}
				addUserQueryMessage={addUserQueryMessage}
				handleChangeSectionRefs={handleChangeSectionRefs}
				setFormLoading={setFormLoading}
				setIsRequestPopupOpen={setIsRequestPopupOpen}
			/>
		</Popup>
	);
};

export default RequestRecordPopup;
